.menu_block  {
        position: relative;
        background: url(../images/menu_bg.png) 0 0 repeat-x #89ab0c;
        border-bottom: 1px solid #7d9e0c;

}

nav>.sf-menu {
        text-align: center;
}
nav{ position:relative;padding: 0px 0 0 0;}
.sf-menu ul {position:absolute;top:-999px; display:none;/* left offset of submenus need to match (see below) */}
.sf-menu li {float:left;position:relative; }
.sf-menu>li {
        float: left;
        position: relative;
        padding: 15px 0 14px;

}



.sf-menu>li+li {
        margin-left:59px;
}

.sf-menu>li:hover, .sf-menu>li.sfHover, .sf-menu>li.current {
        border-color: #a92c11;
}


.sf-menu>li>a{
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        color: #000;

        position: relative;
        font-weight: 300;
        line-height: 24px;
        z-index: 999;
        position: relative;
}


.sf-menu>li.sfHover>a, .sf-menu>li.current>a, .sf-menu>li:hover>a:hover {
        color: #fff;
}



/*================================>> 2 Level <<========================================*/
.sf-menu li ul,.sf-menu li.sfHover>ul{
        top:57px;
        left: -10px;

        width: 130px;
        z-index: 999;
        padding-top: 1px;
        padding-bottom: 1px;
        background: url(../images/sub_bg.png) 0 bottom repeat-x #e0e36a;
}

.sf-menu li.sfHover>ul:after {
        display: block;
        left: 0;
        right: 0;
        top: -3px;
        content: '';
        position: absolute;
        height: 3px;
        background: url(../images/sub_after.png) 0 0 repeat-x;
}
.sf-menu li ul li{
        text-align: left;
        float: none !important;
        margin-bottom: 4px;
                padding-left: 14px;

}

.sf-menu li ul li+li {
        margin-bottom: 6px;
}


.sf-menu li ul li:first-child {
        padding-top: 0;
        border: none;
}

.sf-menu li ul li a.sf-with-ul span{
        position: absolute;
        background: url(../images/menu_arrow.png) 0 0 no-repeat;
        right: -26px;
        top: 8px;
        display: block;
        width: 7px;
        height: 13px;
}

.sf-menu li ul li a{
        font-family: 'Open Sans Condensed', sans-serif;
        font-size: 18px;
        color: #fff;
        line-height: 24px;
        position: relative;
}

.sf-menu li li a:hover,
.sf-menu li.sfHover li.sfHover>a {
        color: #000;

}

/*================================>> 3 Level <<========================================*/
.sf-menu li.sfHover li.sfHover ul{
left: 119px;
top: -1px !important;
}